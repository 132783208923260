import { Image } from 'react-bootstrap';
import { RoletaBordaFixed, RoletaBordaImage } from './Images';


export default function Roulette({ className, result, onAnimationEnd }: any) {
  return (
    <div className="roleta-wrapper">
      <Image className="roleta-fixed" src={RoletaBordaFixed} alt="" fluid />
      <Image className="roleta-borda" src={RoletaBordaImage} alt="" fluid />
      <div
        className={`roleta ${className} ${result}`}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
        <div className="premio"></div>
      </div>
    </div>
  );
}
